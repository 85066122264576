<template>
<div class="page-focus-track">
  <div v-loading="loading" class="focus-track-prediction">
    <div class="gradient-bg"></div>
    <img class="pdm-logo" src="~assets/home/logo-black.png" alt="" />
    <div class="report-name">Focus Track Prediction</div>
    <p class="text">Highlight the focus track(s) in your EP/album, help allocate your marketing budget wisely! You can further pitch the focus track(s) for playlists, launch advertising, social push and Tiktok campaign! Make the most potential track(s) heard by everyone!</p>
    <div class="tips">
      <p class="primary-star">*</p>
      <p>The following predictions are based on the Nigerian PDM model.</p>
    </div>
    <table class="track-table">
      <tr>
        <th class="col-1">Recommended Focus Track</th>
        <th class="col-2 pl10">Track Name</th>
        <th class="col-3">Artist</th>
        <th class="col-4">Play</th>
      </tr>
      <tr v-for="(track, index) in trackList" :key="track.track_name" class="track-info">
        <td class="col-1 no-col">
          <img v-if="index === 0" class="top-icon" src="../../assets/top1.png">
          <img v-if="index === 1" class="top-icon" src="../../assets/top2.png">
          <img v-if="index === 2" class="top-icon" src="../../assets/top3.png">
          <img v-if="track.level === 'S'" class="pdm-stamp" src="~assets/stamp.png">
        </td>
        <td class="col-2">
          <p class="track-name" :title="track.track_name">{{track.track_name}}</p>
        </td>
        <td class="col-3">
          <p class="artist-name" :title="track.artist">{{track.artist || '--'}}</p>
        </td>
        <td class="col-4">
          <i
            class="play-icon"
            :class="isAudioPlay && track.audio_link === mp3Url ? 'icon-pause1' : 'icon-play1'"
            @click="playMusic(track.audio_link)"
          >
          </i>
        </td>
      </tr>
    </table>
    <audio  id="audioPlayer" :src="mp3Url"></audio>
    <p class="email-us">For any enquiries, please email us at PDM@tencent.com. </p>
    <img class="pdm-bg" src="~assets/pdm-bg.png">
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      mp3Url:'',
      isAudioPlay:false,
      audioPlayer:'',
      trackList:[],
      hasSLevel: false,
      loading: false
    }
  },

  mounted() {
    this.getResult()
  },

  methods: {
    back(){
      this.$router.push({
        path: "/task-list"
      })
    },

    async getResult(){
      this.loading = true
      const report_id = this.$util.getParams(window.location.href).report_id
      const res = await this.$store.dispatch("getPrediction", {
        task_id: report_id,
      })
      if (res.code == 2000) {
        this.trackList = res.task_info_list.slice(0, 3)
        this.trackList.forEach(item => {
          if (item.level === 'S') {
            this.hasSLevel = true
          }
        })
        this.loading = false
      } else {
        this.$message({
          message: res.code === 4002 ? 'The report does not exist.' : res.msg,
          type: "error",
          offset: 120,
        });
      }
    },

    playMusic(url) {
      this.audioPlayer = document.getElementById('audioPlayer')
      if (url != this.mp3Url) {
        this.mp3Url = url
        this.isAudioPlay = 0
        this.audioPlayer.load()
        setTimeout(() => {
            this.audioPlayer.play()
        }, 100)
        this.isAudioPlay=1
      } else {
        if (this.audioPlayer.paused) {
          this.audioPlayer.play()
          this.isAudioPlay=1
        } else {
          this.audioPlayer.pause()
          this.isAudioPlay=0
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.page-focus-track {
  width: 100%;
  padding: 100px 0;
}
.focus-track-prediction {
  position: relative;
  padding: 40px;
  margin: 0 auto;
  width: 820px;
  height: 1000px;
  background-color #fff;
  color: #333;
  font-size: 14px;
  user-select: text;
  z-index: 2;

  .gradient-bg {
    position: absolute;
    z-index: 1;  
    top: 0;
    left: 0;
    opacity: 0.25;
    background: linear-gradient(to bottom, #FF9700 0%, #fff 100%);
    width: 900px;
    height: 160px;
  }
  
  .pdm-logo {
    width: 180px;
    margin-top: 10px;
  }

  .report-name {
    font-size: 32px;
    text-align: left;
    color: #FF9700;
    margin-top: 60px;
    font-family: 'Gilroy Bold';
  }

  .text{
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 30px;
  }
  .tips{
    font-size: 13px;
    margin: 30px 0 10px;
    display: flex;
    align-items: center;
  }
  .primary-star {
    font-size: 24px;
    color: #FF9700;
    margin: 7px 2px 0 0;
  }
  .track-table {
    width: 100%;
    border-collapse:collapse;
    border-radius: 10px;
    overflow: hidden;
    .top-icon {
      width: 45px;
    }
    th {
      height: 56px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 500;
    }
    td {
      height: 66px;
      text-align: left;
      padding: 10px;
    }
    tr:nth-child(2n) {
      background-color: #fbfbfb;
    }
    tr:nth-child(2n+1) {
      background-color: #f3f3f3;
    }
    .col-1 {
      width: 240px;
      text-align: center;
    }
    .col-2 {
      width: 250px;
      text-align: left;
    }
    .col-3 {
      width: 140px;
      text-align: center;
    }
    .col-4 {
      text-align: center;
    }
    .no-col {
      position: relative;
      overflow: hidden;
    }
    .pdm-stamp {
      position: absolute;
      opacity: 0.4;
      width: 95px;
      top: 2px;
      left: 35px;
      transform: rotate(-15deg);
    }
    .track-name {
      width: 250px;
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
      font-size: 15px;
      line-height: 66px;
      color: #000;
    }
    .artist-name {
      font-size: 17px;
      font-family: 'Gilroy Bold';
      font-weight: bold;
      color: #FF9700;
      line-height: 66px;
      width: 140px;
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
    }
    .play-icon {
      font-size: 30px;
      color: #b3b3b3;
    }
  }
  .email-us {
    width: 900px;
    text-align: center;
    margin: 80px auto 20px;
    font-size: 13px;
    color: #bbb;
    user-select: text;
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
  }
  .pdm-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
  }
}
</style>
